export const verleihData = [
    {
        title: "Catering",
        desc: "Dem Catering sind keine Grenzen gesetzt - von einer Gala mit einem mehrgängigen Menü bis hin zu einem rustikalen Fest mit Grillstand. Es besteht die Möglichkeit der Leiferung, aber es kann selbstverständlich auch alles vor Ort frisch zubereitet werden.",
        url: "/verleih/catering",
        pictures: [
            {
                src: '/img/catering/pic (1).JPG',
            },
            {
                src: '/img/catering/pic (2).JPG',
            },
            {
                src: '/img/catering/pic (3).JPG',
            },
            {
                src: '/img/catering/pic (4).jpg',
            },
            {
                src: '/img/catering/pic (5).jpg',
            },
            {
                src: '/img/catering/pic (6).jpg',
            },
            {
                src: '/img/catering/pic (7).JPG',
            },
            {
                src: '/img/catering/pic (8).jpg',
            },
        ],
    },
    {
        title: "Dekoration",
        desc: "Add some text for Dekoration",
        url: "/verleih/dekoration",
        pictures: [
            {
                src: '/img/dekoration/pic (1).jpg',
            },
            {
                src: '/img/dekoration/pic (2).jpg',
            },
            {
                src: '/img/dekoration/pic (3).jpg',
            },
            {
                src: '/img/dekoration/pic (4).jpg',
            },
            {
                src: '/img/dekoration/pic (6).JPG',
            },
            {
                src: '/img/dekoration/pic (7).jpg',
            },
        ],
    },
    {
        title: "Mobiliar",
        desc: "Add some text for Mobiliar",
        url: "/verleih/mobiliar",
        pictures: [
            {
                src: '/img/mobialer/pic (1).jpg',
            },
            {
                src: '/img/mobialer/pic (2).JPG',
            },
            {
                src: '/img/mobialer/pic (3).jpg',
            },
            {
                src: '/img/mobialer/pic (4).JPG',
            },
            {
                src: '/img/mobialer/pic (5).jpg',
            },
            {
                src: '/img/mobialer/pic (6).JPG',
            },
            {
                src: '/img/mobialer/pic (7).JPG',
            },
            {
                src: '/img/mobialer/pic (8).jpg',
            },
        ],
    },
    {
        title: "Zelte",
        desc: "Add some text for Zelte",
        url: "/verleih/zelte",
        pictures: [
            {
                src: '/img/zelte/pic (1).jpg',
            },
            {
                src: '/img/zelte/pic (2).JPG',
            },
            {
                src: '/img/zelte/pic (3).JPG',
            },
            {
                src: '/img/zelte/pic (4).jpg',
            },
            {
                src: '/img/zelte/pic (5).jpg',
            },
            {
                src: '/img/zelte/pic (6).JPG',
            },
        ],
    },
    {
        title: "Technik",
        desc: "Add some text for Technik",
        url: "/verleih/technik",
        pictures: [
            {
                src: '/img/technik/pic (1).jpg',
            },
            {
                src: '/img/technik/pic (2).jpg',
            },
            {
                src: '/img/technik/pic (3).JPG',
            },
            {
                src: '/img/technik/pic (4).jpg',
            },
            {
                src: '/img/technik/pic (5).jpg',
            },
            {
                src: '/img/technik/pic (6).jpg',
            },
            {
                src: '/img/technik/pic (7).jpg',
            },
            {
                src: '/img/technik/pic (8).jpg',
            },
        ],
    },
    {
        title: "Lager",
        desc: "Fleißiger Helfer:\nLucas Knebel",
        url: "/team/lager",
        pictures: [
            {
                src: '/img/teamlager/pic (3).jpg',
            },
        ],
    },
    {
        title: "Animateur",
        desc: "disabled",
        url: "/team/animateur",
        pictures: [
            {
                src: '/img/animateur/pic (1).jpg',
            },
            {
                src: '/img/animateur/pic (2).JPG',
            },
            {
                src: '/img/animateur/pic (3).JPG',
            },
            {
                src: '/img/animateur/pic (4).jpg',
            },
            {
                src: '/img/animateur/pic (5).jpg',
            },
            {
                src: '/img/animateur/pic (6).jpg',
            },
        ],
    },
    {
        title: "Fahrzeuge",
        desc: "disabled",
        url: "/team/fahrzeuge",
        pictures: [
            {
                src: '/img/fahrzeuge/pic (1).jpg',
            },
            {
                src: '/img/fahrzeuge/pic (2).jpg',
            },
            {
                src: '/img/fahrzeuge/pic (3).jpg',
            },
            {
                src: '/img/fahrzeuge/pic (4).jpg',
            },
            {
                src: '/img/fahrzeuge/pic (5).jpg',
            },
            {
                src: '/img/fahrzeuge/pic (6).jpg',
            },
        ],
    },
    {
        title: "Halle/Büro",
        desc: "disabled",
        url: "/team/halle",
        pictures: [
            {
                src: '/img/halle/pic (1).jpg',
            },
            {
                src: '/img/halle/pic (2).jpg',
            },
            {
                src: '/img/halle/pic (3).jpg',
            },
            {
                src: '/img/halle/pic (4).jpg',
            },
            {
                src: '/img/halle/pic (5).jpg',
            },
            {
                src: '/img/halle/pic (6).jpg',
            },
            {
                src: "/img/lager/pic (1).jpg"
            },
            {
                src: "/img/lager/pic (2).jpg"
            },
            {
                src: "/img/lager/pic (3).jpg"
            },
            {
                src: "/img/lager/pic (4).jpg"
            },
            {
                src: "/img/lager/pic (5).jpg"
            },
            {
                src: "/img/lager/pic (6).jpg"
            },
        ],
    },
    {
        title: "Abendveranstaltung",
        desc: "disabled",
        url: "/veranstaltung/abendsveranstaltung",
        pictures: [
            {
                src: '/img/abendveranstaltung/pic (1).jpg',
            },
            {
                src: '/img/abendveranstaltung/pic (2).JPG',
            },
            {
                src: '/img/abendveranstaltung/pic (3).JPG',
            },
            {
                src: '/img/abendveranstaltung/pic (4).jpg',
            },
            {
                src: '/img/abendveranstaltung/pic (5).jpg',
            },
            {
                src: '/img/abendveranstaltung/pic (6).JPG',
            },
            {
                src: '/img/abendveranstaltung/pic (7).jpg',
            },
            {
                src: '/img/abendveranstaltung/pic (8).jpg',
            },
        ],
    },
    {
        title: "Virtual Reality",
        desc: "Willkommen in der virtuellen Realität! Tauchen Sie ein in tausende Welten!\n" +
            "Mit dem Virtual Reality Simulator nehmen wir Sie und Ihre Gäste mit auf eine ganze besondere Reise in neue Welten. Durch die Verwendung der neuesten Technik, garantieren wir Ihnen ein einzigartiges, visuelles Erlebnis mit atemberaubenden Spezialeffekten. Lernen Sie auf den folgenden Seiten unsere unterschiedlichen Module kennen und lassen auch Sie sich verzaubern.\n" +
            "Ob eine gemütliche Schlittenfahrt oder wilde Zeitreisen ins Land der Dinosaurier. Durch die Oculus Rift Brillen sehen Sie, passend zu jedem Event verschiedene, themenbezogene Filme. Die Bewegung des Sitzes, der erstklassige Sound und der simulierte Wind garantieren Ihnen den optimalen 5D Effekt. Die anderen Besucher können auf einem Bildschirm sehen, wo die Teilnehmer sich gerade befinden und was sie erleben.\n" +
            "Platzbedarf: 3m x 2,5m x 2,5m\n" +
            "Strom: 230 V / 16 A\n" +
            "inkl. 2 hochwertiger Oculus Rift Brillen, \n" +
            "Hygienepaket & 8 Filmen\n",
        url: "/eventmodule/vr",
        pictures: [
            {
                src: '/img/vr/pic (2).JPG',
            },
            {
                src: '/img/vr/pic (3).jpg',
            },
            {
                src: '/img/vr/pic (4).jpg',
            },
            {
                src: '/img/vr/pic (6).jpg',
            },
            {
                src: '/img/vr/pic (7).jpg',
            },
        ],
    },
]